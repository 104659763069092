import React from "react";
import { withStyles } from "@material-ui/core";
import SubPageBase from "../../components/SubPageBase";
import Typography from "@material-ui/core/Typography";
import degerlerimizImg from "../../images/degerlerimiz.png";

const styles = (theme) => ({
  root: {
    fontSize: "calc(2vw + 2vh)",
  },
  content: {
    width: "80vw",
    marginRight: 0,
    margin: "auto",
    padding: "5vw",
    textAlign: "justify",
  },
});

const IndexPage = ({ classes }) => (
  <SubPageBase title="DEĞERLERİMİZ" img={degerlerimizImg} className={classes.root}>
    <div className={classes.content}>
      <Typography variant="h3" gutterBottom>
        Canlılık
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Hayat dolu, enerjik ve pozitif olmaktır.
        <br />
        Cesaret ederim, karar alırım, adapte ederim ve gerekirse değiştiririm.
        <br />
        Harekete geçmeyi severim.
        <br />
        Oyuna her zaman kazanmak için başlarım!
      </Typography>
      <Typography variant="h3" gutterBottom>
        Sorumluluk
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Kendi hayatının aktörü olmak ve sorumlu davranmaktır.
        <br />
        Yapacaklarımı net bir şekilde söyler ve söylediklerimi hayata geçiririm.
        <br />
        Değer yaratmak amacıyla çalışırım ve yarattığım değerin etkilerini takip ederim.
        <br />
        Hata yaptığımda en büyük önceliğim hatamı düzeltmektir.
        <br />
        Tüm paydaşların verdiğim kararlardan her zaman faydalanmasını sağlarım.
      </Typography>
      <Typography variant="h3" gutterBottom>
        Cömertlik
      </Typography>

      <Typography variant="subtitle1" gutterBottom>
        Bir şeyleri kalpten yapmak ve canlılara karşı duyarlı olmaktır.
        <br />
        Takım oyuncusuyum, destek olmak için her zaman hazırım.
        <br />
        Faydalı olabilecek her konuda paylaşımcı olurum.
        <br />
        Tüm paydaşlarımı “arkadaş” olarak görürüm.
        <br />
        Başkalarına ve kendime iyi bakarım.
        <br />
        Dayanışmanın gücüne inanırım.
        <br />
      </Typography>

      <Typography variant="h3" gutterBottom>
        Özgünlük
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Kendine ve başkalarına karşı dürüst olmaktır.
        <br />
        Burada her zaman kendim olurum.
        <br />
        Söyleyeceklerimi şeffaf bir şekilde ifade ederim, insanları önemser ve onlara samimi davranırım.
        <br />
        Gezegenimize karşı sorumluluklarımı dikkate alarak harekete geçerim.
        <br />
      </Typography>
    </div>
  </SubPageBase>
);

export default withStyles(styles)(IndexPage);
